.trace-nav-top button.nav-button {
    margin-right: 0.25em;
    margin-bottom: 1em;
}

.trace-nav button.nav-button {
    padding: 0;
    margin: 0;
    border: none;
    display: inline;
    text-decoration: none;
}

button.nav-button.active,
button.nav-button:active,
button.nav-button:hover {
    text-decoration: underline;
}

button.nav-button.diff-lhs {
    color: rgba(179, 0, 0, 1);
    background: rgba(179, 0, 0, 0.05);
}

button.nav-button.diff-rhs {
    color: rgba(64, 102, 25, 1);
    background: rgba(64, 102, 25, 0.05);
}

ul.trace-nav {
    padding-left: 0;
}

ul.trace-nav ul.trace-nav {
    padding-left: 2rem;
}

ul.trace-nav li {
    list-style-type: none;
    white-space: nowrap;
}

ul.trace-nav .caret::before {
    color: black;
    display: inline-block;
    margin-right: 6px;
    content: "\229E";
}

ul.trace-nav .caret-open::before {
    content: "\229F";
}

ul.trace-nav .nocaret::before {
    color: black;
    display: inline-block;
    margin-right: 6px;
    content: "\22A1";
}

/* Hide the nested list */
ul.trace-nav-closed {
    display: none;
}

/* diff styling */
.ins {
    color: rgba(64, 102, 25, 1);
    background: rgba(64, 102, 25, 0.05);
}

.ins .attributes {
    color: rgba(64, 102, 25, 0.5);
}

.del {
    color: rgba(179, 0, 0, 1);
    background: rgba(179, 0, 0, 0.05);
}

.del .attributes {
    color: rgba(179, 0, 0, 0.5);
}

.same {
    color: rgba(0, 0, 0, 1);
    background: rgb(255, 255, 255);
}

.same .attributes {
    color: rgba(0, 0, 0, 0.5);
}

.noattrs .attributes {
    display: none;
}

.noattrs div.ins:hover .attributes {
    display: initial;
}

.noattrs div.del:hover .attributes {
    display: initial;
}

.noattrs div.same:hover .attributes {
    display: initial;
}

/* WIP: vertical guides styling */
#explorer pre {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    line-height: normal;
}

#explorer pre code.vbar {
    border-right: 1px solid #dddddd;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0ch;
    z-index: 10;
}

#explorer pre code.plan {
    display: block;
    z-index: 100;
}